<template>
  <div
    class="flex flex-center overflow-visible"
    ref="motionRef"
    @mouseenter="onMouseEnter()"
    @mouseleave="onMouseLeave()"
  >
    <circle-text-lines
      :circle-type-options="{
        activeColors: true,
        direction: 1,
        fill: computedFill,
        forceHeight: true,
        forceWidth: true,
        radius: radius ? radius : denseView ? 600 : 800,
        stroke: true,
        strokeColor: 'accent',
        strokeWidth: denseView ? 2 : 5,
      }"
      :text="title"
      :tag="tag"
      :text-class="`${
        denseView ? textSmall : textLarge
      } q-mb-sm q-mt-md text-glow-light`"
    />
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useMotion } from "@vueuse/motion";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { usePreferencesStore } from "src/stores/preferences";
import CircleTextLines from "./CircleTextLines.vue";

defineOptions({ name: "TextHeaderCircle" });

const props = defineProps({
  fill: { type: String, default: "dark" },
  hoverable: { type: Boolean, default: false },
  radius: { type: [Boolean, Number], default: false },
  tag: { type: String, default: "h1" },
  textLarge: { type: String, default: "text-h2" },
  textSmall: { type: String, default: "text-h4" },
  title: { type: String, default: "Hello" },
  view: { type: String, default: "default" },
});
const $q = useQuasar();
const preferencesStore = usePreferencesStore();
const { reducedMotion } = storeToRefs(preferencesStore);
const motionRef = ref(null);
const computedFill = computed(() => props.fill);
const computedReducedMotion = computed(() => reducedMotion.value);
const drawerView = computed(() => props.view === "drawer");
const denseView = computed(() => $q.screen.lt.md || drawerView.value);

let motionInstance = null;

const createMotionInstance = () => {
  return useMotion(motionRef, {
    initial: {
      y: 100,
      opacity: 0,
    },
    enter: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 350,
        damping: 20,
        delay: 50,
        onComplete: () => {
          motionInstance.apply("levitate");
        },
      },
    },
    levitate: {
      y: 5,
      transition: {
        duration: 2000,
        repeat: Infinity,
        ease: "easeInOut",
        repeatType: "mirror",
      },
    },
    hover: {
      scale: 1.1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20,
      },
    },
    leave: {
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20,
      },
    },
  });
};

const applyAnimations = () => {
  if (!computedReducedMotion.value && motionInstance) {
    motionInstance.apply("enter");
  } else if (motionInstance) {
    motionInstance.stop();
  }
};

const onMouseEnter = () => {
  if (!computedReducedMotion.value && motionInstance && props.hoverable) {
    motionInstance.apply("hover");
  }
};

const onMouseLeave = () => {
  if (!computedReducedMotion.value && motionInstance && props.hoverable) {
    motionInstance.apply("leave");
  }
};

watch(computedReducedMotion, () => {
  if (motionInstance) {
    applyAnimations();
  } else if (!computedReducedMotion.value) {
    motionInstance = createMotionInstance();
    applyAnimations();
  }
});

onMounted(async () => {
  await nextTick();
  if (!computedReducedMotion.value) {
    motionInstance = createMotionInstance();
    applyAnimations();
  }
});
</script>
