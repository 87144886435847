<template>
  <div
    class="absolute-full overflow-visible"
    :class="[`blur-${blur}`, `bg-${color}-fade`]"
  />
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "DrawBackground",
  props: {
    color: { type: String, default: "primary" },
    blur: { type: String, default: "lg" },
  },
  setup() {
    return {};
  },
});
</script>
