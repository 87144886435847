import { ref, watch } from "vue";
import { defineStore } from "pinia";

export const useAuraStore = defineStore("aura", () => {
  const aura = ref(null);
  const auraClone = ref("");
  const auraRequest = ref(false);
  const requestId = ref("");

  watch(aura, (newAura) => {
    // console.log(newAura);
  });
  return {
    aura,
    auraClone,
    auraRequest,
    requestId,
  };
});
