<template>
  <component
    :aria-label="to ? toAlt : null"
    :class="[...classes]"
    :is="to ? 'router-link' : 'div'"
    v-bind="to ? { to } : {}"
  >
    <q-img
      :alt="alt"
      :class="shadow ? `shadow-${shadow}` : undefined"
      :height="height || undefined"
      :loading="loading"
      :position="position"
      :ratio="ratio"
      :sizes="
        !data.image && !computedZoomEnabled
          ? '(max-width: 400px) 400w, (min-width: 400px) and (max-width: 800px) 800w, (min-width: 800px) and (max-width: 1200px) 1200w, (min-width: 1200px) 1600w'
          : null
      "
      :srcset="
        !data.image && !computedZoomEnabled
          ? `${data.sm} 400w, ${data.md} 800w, ${data.lg} 1200w, ${data.xl} 1600w`
          : null
      "
      :src="computedZoomEnabled ? data.xx : data.image || undefined"
      :width="width || undefined"
      @load="onLoad"
    >
      <template #loading>
        <q-spinner-tail color="primary" size="42%" />
      </template>
    </q-img>
  </component>
</template>

<script setup>
import { computed, inject } from "vue";
import { getAverageColor } from "src/composables/image/useImageColor";

defineOptions({ name: "ImageCard" });

const emits = defineEmits(["colorValue"]);

const props = defineProps({
  active: { type: Boolean, default: true },
  alt: { type: String, default: "image" },
  classes: { type: Array, default: () => ["absolute-full", "flex"] },
  height: { type: [Boolean, String], default: false },
  data: { type: Object, default: () => ({}) },
  getColor: { type: Boolean, default: false },
  index: { type: Number, default: 0 },
  loading: { type: String, default: "lazy" },
  position: { type: String, default: "50% 50%" },
  ratio: { type: Number, default: 0.6667 },
  shadow: { type: [Boolean, Number], default: 1 },
  to: { type: [Boolean, Object], default: false },
  toAlt: { type: String, default: "link to image" },
  width: { type: [Boolean, String], default: false },
  zoomEnabled: { type: Boolean, default: false },
});

const bus = inject("bus");

const computedZoomEnabled = computed(() => props.zoomEnabled);

const onLoad = () => {
  bus.emit("imageLoadSound");
  if (!props.getColor) return;
  const image = props.data.aura || props.data.image;
  getAverageColor(image, (color) => {
    emits("colorValue", color);
  });
};
</script>
