let canvas, context;

function initializeCanvas() {
  if (!canvas) {
    canvas = document.createElement("canvas");
    context = canvas.getContext("2d", { willReadFrequently: true });
  }
}

export function getAverageColor(imageSrc, callback) {
  initializeCanvas();

  const img = new Image();
  img.crossOrigin = "Anonymous";
  img.src = imageSrc;

  img.onload = () => {
    canvas.width = img.width;
    canvas.height = img.height;
    context.drawImage(img, 0, 0, img.width, img.height);

    const imageData = context.getImageData(0, 0, img.width, img.height);
    const data = imageData.data;

    let r = 0,
      g = 0,
      b = 0;
    for (let i = 0; i < data.length; i += 4) {
      r += data[i];
      g += data[i + 1];
      b += data[i + 2];
    }

    const pixelCount = img.width * img.height;
    r = Math.floor(r / pixelCount);
    g = Math.floor(g / pixelCount);
    b = Math.floor(b / pixelCount);

    const isDark = r * 0.299 + g * 0.587 + b * 0.114 < 150;
    callback(isDark ? "light" : "dark");
  };

  img.onerror = () => {
    console.error("Failed to load image");
    callback("light");
  };
}
